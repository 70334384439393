<template>
  <div class="article-container">
    <el-card class="filter-card">
      <!-- 数据筛选表单 -->
      <el-form ref="form" label-width="80px" size="mini">
        <div class="filter-card">
          请选择时间段：
          <el-date-picker
            value-format="yyyy-MM-dd 00:00:00"
            v-model="form.createTimeAfter"
            type="date"
            placeholder="选择开始日期"
          >
          </el-date-picker>

          <el-date-picker
            value-format="yyyy-MM-dd 23:59:59"
            v-model="form.creatTimeBefore"
            type="date"
            placeholder="选择结束日期"
          >
          </el-date-picker>

          完成状态:
          <el-select v-model="form.processes" placeholder="请选择">
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
          研发跟进人:
          <el-input
            class="input"
            v-model="form.personLiable"
            placeholder="请输入责任人"
          ></el-input>
          <el-button type="primary" :disabled="loading" @click="cleardata()"
            >清空</el-button
          >

          <el-button type="primary" :disabled="loading" @click="loadArticles(1)"
            >查询</el-button
          >
          <el-button
            type="info"
            :disabled="loading"
            @click="ifgaoji = !ifgaoji"
            >{{ ifgaoji ? "收起" : "高级查询" }}</el-button
          >
        </div>
        <div v-if="ifgaoji" class="flexparents rightgap zdhh">
          <div>
            工单号:
            <el-input
              class="input rightgap"
              v-model="form.workOrderNo"
              placeholder="请输入工单号"
            ></el-input>
          </div>

          <div>
            创建人:
            <el-input
              class="input rightgap"
              v-model="form.createdName"
              placeholder="请输入创建人"
            ></el-input>
          </div>

          <div>
            销售员:
            <el-input
              class="input rightgap"
              v-model="form.salesman"
              placeholder="请输入销售员"
            ></el-input>
          </div>

          <div>
            问题描述:
            <el-input
              class="input rightgap"
              v-model="form.description"
              placeholder="请输入"
            ></el-input>
          </div>
          <div>
            解释说明:
            <el-input
              class="input rightgap"
              v-model="form.remark"
              placeholder="请输入内容"
            ></el-input>
          </div>

          <div>
            紧急程度:
            <el-select
              class="flexparents rightgap"
              v-model="form.urgentType"
              placeholder="请选择"
            >
              <el-option
                v-for="item in urgentTypeoption"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </div>

          <el-button type="success" plain @click="showshaixuan()"
            >内容显示</el-button
          >
        </div>
      </el-form>
      <!-- /数据筛选表单 -->
    </el-card>

    <el-card class="box-card">
      <div slot="header" class="clearfix">
        根据筛选条件共查询到 {{ totalCount }} 条结果：
      </div>
      <!-- 数据列表 -->
      <!--
        Table 表格组件 
        1、把需要展示的数组列表数据绑定给 table 组件的 data 属性
          注意：你不用去 v-for 遍历，它自己会遍历
        2、设计表格列 el-table-column
          width 可以设定表格列的宽度
          label 可以设定列的标题
          prop  用来设定要渲染的列表项数据字段，只能展示文本

        3、表格列默认只能渲染普通文本，如果需要展示其它内容，例如放个按钮啊、放个图片啊，那就需要自定义表格列模板了：https://element.eleme.cn/#/zh-CN/component/table#zi-ding-yi-lie-mo-ban
       -->
      <el-table
        :data="articles"
        :height="tableHeight"
        style="width: 100%"
        class="list-table"
        size="mini"
        v-loading="loading"
        :row-class-name="tableRowClassName"
      >
        <el-table-column prop="orderNo" width="160" label="工单号">
        </el-table-column>

        <el-table-column
          prop="station"
          label="当前工站"
          key="station"
          v-if="GLYFcheckList.includes('station')"
        >
          <template slot-scope="scope">
            <el-tag :type="progressStatus[scope.row.station].type">{{
              progressStatus[scope.row.station].text
            }}</el-tag>
          </template>
        </el-table-column>

        <el-table-column
          prop="personLiable"
          width="160"
          label="研发跟进人"
          v-if="GLYFcheckList.includes('personLiable')"
        >
        </el-table-column>
        <el-table-column
          label="问题状态"
          v-if="GLYFcheckList.includes('process')"
        >
          <!-- 如果需要在自定义列模板中获取当前遍历项数据，那么就在 template 上声明 slot-scope="scope" -->
          <template slot-scope="scope">
            <el-tag :type="processStatus[scope.row.process].type">{{
              processStatus[scope.row.process].text
            }}</el-tag>
          </template>
        </el-table-column>

        <el-table-column
          prop="description"
          width="300"
          label="问题描述"
          v-if="GLYFcheckList.includes('description')"
        ></el-table-column>

        <el-table-column
          width="300"
          label="解释说明"
          v-if="GLYFcheckList.includes('remark')"
        >
          <p slot-scope="scope" style="white-space: pre-line">
            {{ scope.row.remark }}
          </p>
        </el-table-column>

        <el-table-column prop="attribute" label="状态">
          <template slot-scope="scope">
            <p v-if="scope.row.attribute === 1">研发</p>
            <p v-if="scope.row.attribute === 0">需求</p>
          </template>
        </el-table-column>
        <el-table-column
          prop="developStuckPoint"
          label="研发卡点"
        ></el-table-column>
        <el-table-column
          prop="solution"
          width="300"
          label="解决方案"
        ></el-table-column>
        <el-table-column
          prop="tapdLink"
          width="300"
          label="TAPD"
        ></el-table-column>

        <el-table-column
          prop="area"
          label="大区"
          v-if="GLYFcheckList.includes('area')"
        >
          <template v-slot="scope">
            {{
              areaoptions[scope.row.area] && areaoptions[scope.row.area].label
            }}
          </template>
        </el-table-column>

        <el-table-column
          prop="faeOperatorName"
          label="FAE处理人"
          v-if="GLYFcheckList.includes('faeOperatorName')"
        ></el-table-column>
        <el-table-column
          prop="customerName"
          label="客户(国内)"
          v-if="GLYFcheckList.includes('customerName')"
        ></el-table-column>

        <el-table-column
          prop="deptName"
          label="所属部门"
          v-if="GLYFcheckList.includes('deptName')"
        ></el-table-column>
        <el-table-column
          prop="salesman"
          label="销售"
          v-if="GLYFcheckList.includes('salesman')"
        ></el-table-column>
        <el-table-column
          prop="type"
          label="客诉类型"
          v-if="GLYFcheckList.includes('type')"
        >
          <template v-slot="scope">
            {{
              problemtypeaoptions[scope.row.type] &&
              problemtypeaoptions[scope.row.type].label
            }}
          </template>
        </el-table-column>
        <el-table-column
          prop="productType"
          label="产品类型"
          v-if="GLYFcheckList.includes('productType')"
        >
          <template v-slot="scope">
            {{
              prodectoptions[scope.row.productType] &&
              prodectoptions[scope.row.productType].label
            }}
          </template></el-table-column
        >
        <el-table-column
          prop="networkType"
          label="网络制式"
          v-if="GLYFcheckList.includes('networkType')"
        ></el-table-column>
        <el-table-column
          prop="productModelType"
          label="机型"
          v-if="GLYFcheckList.includes('productModelType')"
        ></el-table-column>
        <el-table-column
          prop="createdName"
          label="创建人"
          v-if="GLYFcheckList.includes('createdName')"
        ></el-table-column>
        <el-table-column
          label="紧急程度"
          v-if="GLYFcheckList.includes('urgentType')"
        >
          <!-- 如果需要在自定义列模板中获取当前遍历项数据，那么就在 template 上声明 slot-scope="scope" -->
          <template slot-scope="scope">
            <el-tag :type="urgentStatus[scope.row.urgentType].type">{{
              urgentStatus[scope.row.urgentType].text
            }}</el-tag>
          </template>
        </el-table-column>

        <el-table-column width="180" label="操作" fixed="right">
          <template slot-scope="scope">
            <div>
              <el-tooltip
                content="查看详情/处理工单"
                placement="top"
                :open-delay="0"
                :enterable="false"
              >
                <el-button
                  size="mini"
                  @click="addplan(scope.row)"
                  circle
                  icon="el-icon-search"
                  type="primary"
                ></el-button>
              </el-tooltip>

              <el-tooltip
                content="退回至FAE"
                placement="top"
                :open-delay="0"
                :enterable="false"
              >
                <el-button
                  size="mini"
                  @click="showtranslatewindows(scope.row)"
                  circle
                  icon="el-icon-position"
                  type="primary"
                ></el-button>
              </el-tooltip>
              <el-tooltip
                content="完成"
                placement="top"
                :open-delay="0"
                :enterable="false"
              >
                <el-button
                  size="mini"
                  @click="finish(scope.row)"
                  circle
                  icon="el-icon-close"
                  type="primary"
                ></el-button>
              </el-tooltip>

              <el-tooltip
                content="变更研发跟进人"
                placement="top"
                :open-delay="0"
                :enterable="false"
              >
                <el-button
                  size="mini"
                  @click="ChangeTech(scope.row.workOrderId)"
                  circle
                  icon="el-icon-s-custom"
                  type="primary"
                ></el-button>
              </el-tooltip>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <!-- /数据列表 -->

      <!-- 列表分页 -->
      <!--
        total 用来设定总数据的条数
        它默认按照 10 条每页计算总页码
        page-size 每页显示条目个数，支持 .sync 修饰符，默认每页 10 条

        90 3 90 / 3 = 30
       -->
      <el-pagination
        layout="sizes,prev, pager, next"
        background
        :total="totalCount"
        :page-sizes="[10, 20, 50, 100]"
        @size-change="handleSizeChange"
        :page-size="form.size"
        :disabled="loading"
        :current-page.sync="form.page"
        @current-change="onCurrentChange"
      />
      <!-- /列表分页 -->
    </el-card>

    <el-dialog
      @closed="loadArticles"
      title="查看详情"
      :visible.sync="planVisable"
      append-to-body
      width="1100px"
    >
      <el-steps
        :active="steptype"
        finish-status="success"
        class="gap"
        style="padding-left: 16px; padding-right: 16px"
      >
        <el-step title="开始" :description="FAEdatile.createdTime"></el-step>
        <el-step
          title="FAE处理中"
          :description="FAEdatile.firstBrowseTime"
        ></el-step>
        <el-step
          title="转发至研发"
          :description="yanfadatile.createdTime"
        ></el-step>
        <el-step
          title="研发处理中"
          :description="yanfadatile.firstBrowseTime"
        ></el-step>
        <el-step
          title="研发处理完成"
          :description="yanfadatile.completedTime"
        ></el-step>
        <el-step
          title="FAE确认完成中"
          :description="yanfadatile.completedTime"
        ></el-step>
        <el-step title="完成" :description="FAEdatile.completedTime"></el-step>
      </el-steps>
      <div class="destitle">基本信息</div>
      <el-descriptions class="desbasebody" :column="3">
        <el-descriptions-item label="订单号">{{
          FAEdatile.orderNo
        }}</el-descriptions-item>
        <el-descriptions-item label="型号">{{
          FAEdatile.productModelType
        }}</el-descriptions-item>
        <el-descriptions-item label="大区">{{
          areaoptions[FAEdatile.area] && areaoptions[FAEdatile.area].label
        }}</el-descriptions-item>
        <el-descriptions-item label="客诉类型">{{
          problemtypeaoptions[FAEdatile.type] &&
          problemtypeaoptions[FAEdatile.type].label
        }}</el-descriptions-item>
        <el-descriptions-item label="涉及平台">{{
          pingtaioptions[FAEdatile.platform] &&
          pingtaioptions[FAEdatile.platform].label
        }}</el-descriptions-item>
        <el-descriptions-item label="紧急程度">
          {{ urgentStatustext[FAEdatile.urgentType] }}
        </el-descriptions-item>
        <el-descriptions-item label="FAE处理人">{{
          FAEdatile.faeOperatorName
        }}</el-descriptions-item>
        <el-descriptions-item label="研发跟进人">{{
          FAEdatile.personLiable
        }}</el-descriptions-item>
        <el-descriptions-item label="创建人">{{
          FAEdatile.createdName
        }}</el-descriptions-item>
      </el-descriptions>

      <div class="desbody" v-if="this.esservedatiledata.orderNo != null">
        <el-descriptions :column="4">
          <el-descriptions-item label="IMEI">{{
            esservedatiledata.imei
          }}</el-descriptions-item>
          <el-descriptions-item label="平台/APP">{{
            esservedatiledata.platform
          }}</el-descriptions-item>
          <el-descriptions-item label="设备状态">{{
            esservedatiledata.deviceStatus
          }}</el-descriptions-item>
          <el-descriptions-item label="创建时间">{{
            esservedatiledata.createdTime
          }}</el-descriptions-item>
          <el-descriptions-item label="购买渠道">{{
            esservedatiledata.channel
          }}</el-descriptions-item>
          <el-descriptions-item label="国家">{{
            esservedatiledata.country
          }}</el-descriptions-item>
          <el-descriptions-item label="其他联系方式">{{
            esservedatiledata.otherContactInfo
          }}</el-descriptions-item>
          <el-descriptions-item label="设备LED灯状态">{{
            esservedatiledata.ledStatus
          }}</el-descriptions-item>
          <el-descriptions-item label="SIM卡运营商">{{
            esservedatiledata.simCardOperator
          }}</el-descriptions-item>
          <el-descriptions-item label="使用场景">{{
            esservedatiledata.usageScenario
          }}</el-descriptions-item>
          <el-descriptions-item label="安装的车型号">{{
            esservedatiledata.installedVehicleModel
          }}</el-descriptions-item>
          <el-descriptions-item label="Email">{{
            esservedatiledata.email
          }}</el-descriptions-item>
        </el-descriptions>
        <el-descriptions direction="vertical" :column="3">
          <el-descriptions-item label="问题描述" :span="3">
            <p style="white-space: pre-line">
              {{ esservedatiledata.description }}
            </p>
          </el-descriptions-item>

          <el-descriptions-item label="图片" :span="16">
            <el-image
              style="width: 100px; height: 100px; margin-right: 10px"
              v-for="{ url } in esservedatiledata.image"
              :key="url"
              :src="url"
              :previewSrcList="ESpreviewList"
            />
          </el-descriptions-item>
          <el-descriptions-item label="附件" :span="16">
            <p v-for="{ url, path } in esservedatiledata.attachment" :key="url">
              <a :href="url" target="_blank">{{ path }}</a>
            </p>
          </el-descriptions-item>
        </el-descriptions>
      </div>

      <div class="destitle" v-if="this.kfdatile.orderNo != null">客服详情</div>

      <div class="desbody" v-if="this.kfdatile.orderNo != null">
        <el-descriptions :column="3">
          <el-descriptions-item label="客服">{{
            kfdatile.createdName
          }}</el-descriptions-item>
          <el-descriptions-item label="客户">{{
            kfdatile.customerName
          }}</el-descriptions-item>
          <el-descriptions-item label="客户手机号">{{
            kfdatile.phoneNumber
          }}</el-descriptions-item>
          <el-descriptions-item label="客诉标题">{{
            kfdatile.title
          }}</el-descriptions-item>
          <el-descriptions-item label="问题原因">{{
            kfdatile.reason
          }}</el-descriptions-item>
          <el-descriptions-item label="创建时间">{{
            kfdatile.createdTime
          }}</el-descriptions-item>
        </el-descriptions>
        <el-descriptions direction="vertical" :column="3">
          <el-descriptions-item label="问题描述" :span="3">
            <p style="white-space: pre-line">
              {{ kfdatile.description }}
            </p>
          </el-descriptions-item>
          <el-descriptions-item label="备注" :span="3">{{
            kfdatile.remark
          }}</el-descriptions-item>
          <el-descriptions-item label="图片" :span="3">
            <el-image
              style="width: 100px; height: 100px; margin-right: 10px"
              fit="fit"
              v-for="{ url } in kfdatile.image"
              :key="url"
              :src="url"
              :previewSrcList="KFpreviewList"
            />
          </el-descriptions-item>
        </el-descriptions>
      </div>

      <div class="destitle">FAE详情</div>

      <div class="desbody">
        <el-descriptions :column="3">
          <el-descriptions-item label="关键字">{{
            FAEdatile.keyword
          }}</el-descriptions-item>
          <el-descriptions-item label="当前卡点">{{
            difoptions[FAEdatile.stuckPoint] &&
            difoptions[FAEdatile.stuckPoint].label
          }}</el-descriptions-item>
          <el-descriptions-item label="创建时间">{{
            FAEdatile.createdTime
          }}</el-descriptions-item>
        </el-descriptions>
        <el-descriptions direction="vertical" :column="3">
          <el-descriptions-item label="问题处理方案" :span="3">
            <el-timeline>
              <el-timeline-item
                v-for="(activity, index) in solutionlinedata"
                :key="index"
                :timestamp="activity.time"
              >
                <p style="white-space: pre-line">
                  {{ activity.detail }}
                </p>
              </el-timeline-item>
            </el-timeline>
          </el-descriptions-item>
          <el-descriptions-item label="描述" :span="3">
            <p style="white-space: pre-line">
              {{ FAEdatile.description }}
            </p>
          </el-descriptions-item>
          <el-descriptions-item label="客户原话/其他" :span="3">{{
            FAEdatile.customerDescription
          }}</el-descriptions-item>
          <el-descriptions-item label="图片" :span="3">
            <el-image
              style="width: 100px; height: 100px; margin-right: 10px"
              fit="fit"
              v-for="{ url } in FAEdatile.image"
              :key="url"
              :src="url"
              :previewSrcList="FAEpreviewList"
            />
          </el-descriptions-item>

          <el-descriptions-item label="附件" :span="3">
            <p v-for="{ url, path } in FAEdatile.attachment" :key="url">
              <a :href="url" target="_blank">{{ path }}</a>
            </p>
          </el-descriptions-item>
        </el-descriptions>
      </div>

      <div class="destitle" v-if="this.yanfadatile.orderNo != null">
        研发详情
      </div>

      <div class="desbody" v-if="this.yanfadatile.orderNo != null">
        <el-descriptions :column="3">
          <el-descriptions-item :label="$t('createdTime')">{{
            yanfadatile.createdTime
          }}</el-descriptions-item>
          <el-descriptions-item :label="$t('zhuangtai')">{{
            attributeoptions[yanfadatile.attribute] &&
            attributeoptions[yanfadatile.attribute].label
          }}</el-descriptions-item>
          <el-descriptions-item :label="$t('personLiable')">{{
            yanfadatile.personLiable
          }}</el-descriptions-item>
        </el-descriptions>
        <el-descriptions direction="vertical" :column="3">
          <el-descriptions-item label="解释说明" :span="3">
            <el-timeline>
              <el-timeline-item
                v-for="(activity, index) in remakelinedate"
                :key="index"
                :timestamp="activity.time"
              >
                {{ activity.detail }}
              </el-timeline-item>
            </el-timeline>
          </el-descriptions-item>

          <el-descriptions-item :label="$t('tapdlianjie')" :span="3">{{
            yanfadatile.tapdLink
          }}</el-descriptions-item>

          <el-descriptions-item :label="$t('yfkd')" :span="3">{{
            yanfadatile.developStuckPoint
          }}</el-descriptions-item>

          <el-descriptions-item :label="$t('jjfangan')" :span="3">{{
            yanfadatile.solution
          }}</el-descriptions-item>

          <el-descriptions-item label="图片" :span="3">
            <el-image
              style="width: 100px; height: 100px; margin-right: 10px"
              fit="fit"
              v-for="{ url } in yanfadatile.imageInfos"
              :key="url"
              :src="url"
              :previewSrcList="YFpreviewList"
            />
          </el-descriptions-item>

          <el-descriptions-item label="附件" :span="3">
            <p v-for="{ url, path } in yanfadatile.fileInfos" :key="url">
              <a :href="url" target="_blank">{{ path }}</a>
            </p>
          </el-descriptions-item>
        </el-descriptions>
      </div>

      <el-form
        ref="form"
        :model="form"
        label-width="80px"
        style="margin-top: 10px"
      >
        <el-form-item label="解释说明">
          <el-input type="textarea" v-model="dataildata.solveremark"></el-input>
        </el-form-item>
        <el-form-item label="图片">
          <el-col :span="22">
            <el-upload
              action="/api/procedure/development-work-order/uploadImg"
              :headers="uploadHeaders"
              accept=".jpg,.png"
              :limit="5"
              multiple
              list-type="picture"
              :file-list="imageList"
              :on-preview="handlePictureCardPreview"
              :on-remove="handleImageRemove"
              :on-success="handleImgUploadSuccess"
            >
              <el-button size="mini" type="primary"
                >选择图片（仅限jpg, .png）</el-button
              >
            </el-upload>
          </el-col>
        </el-form-item>

        <el-form-item label="文件">
          <el-col :span="22">
            <el-upload
              action="/api/procedure/development-work-order/uploadFile"
              :headers="uploadHeaders"
              :limit="5"
              :file-list="fileList"
              :on-remove="handleRemove"
              :on-success="handleUploadSuccess"
            >
              <el-button size="mini" type="primary">点击上传</el-button>
            </el-upload>
          </el-col>
        </el-form-item>
      </el-form>

      <el-dialog
        width="30%"
        title="编辑详情"
        :visible.sync="innerVisible"
        append-to-body
      >
        <el-form :model="editform" label-width="80px">
          <el-form-item label="TAPD链接">
            <el-input v-model="editform.tapdLink"></el-input>
          </el-form-item>
          <el-form-item label="状态">
            <el-select v-model="editform.attribute">
              <el-option label="需求" :value="0"></el-option>
              <el-option label="研发" :value="1"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="研发卡点">
            <el-input
              type="textarea"
              v-model="editform.developStuckPoint"
            ></el-input>
          </el-form-item>
          <el-form-item label="解决方案">
            <el-input type="textarea" v-model="editform.solution"></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="EditSubmit">确定</el-button>
            <el-button @click="innerVisible = false">取消</el-button>
          </el-form-item>
        </el-form>
      </el-dialog>

      <span slot="footer" class="dialogfooter">
        <el-button type="success" plain @click="solveOrder">确定</el-button>
        <el-button type="success" plain @click="showinnerVisible"
          >编辑详情</el-button
        >
      </span>
    </el-dialog>
    <el-dialog title="退回给FAE" :visible.sync="showtranslate">
      <!-- <el-cascader
        style="width: 100%"
        id="'edit'"
        v-model="translateTO"
        :options="treedata"
        :placeholder="'请选择部门'"
        :props="{ checkStrictly: true, label: 'name', value: 'deptId' }"
        clearable
        @change="handleSelectDept"
      /> -->
      <el-input
        type="textarea"
        placeholder="请输入退回原因"
        v-model="translateremark"
      ></el-input>

      <div slot="footer" class="dialog-footer">
        <el-button @click="showtranslate = false">取 消</el-button>
        <el-button type="primary" @click="translate">确 定</el-button>
      </div>
    </el-dialog>
    <el-dialog
      modal-append-to-body
      :modal="false"
      :visible.sync="ifshowshaixuan"
    >
      <img width="100%" :src="dialogImageUrl" alt="" />
    </el-dialog>

    <el-dialog :visible.sync="ifshowshaixuan">
      <el-checkbox-group v-model="GLYFcheckList">
        <el-checkbox label="station">当前工站</el-checkbox>
        <el-checkbox label="personLiable">研发跟进人</el-checkbox>
        <el-checkbox label="process">问题状态</el-checkbox>
        <el-checkbox label="description">问题描述</el-checkbox>
        <el-checkbox label="remark">解释说明</el-checkbox>
        <el-checkbox label="area">大区</el-checkbox>
        <el-checkbox label="faeOperatorName">FAE处理人</el-checkbox>
        <el-checkbox label="customerName">客户(国内)</el-checkbox>
        <el-checkbox label="salesman">销售</el-checkbox>
        <el-checkbox label="type">客诉类型</el-checkbox>
        <el-checkbox label="productType">产品类型</el-checkbox>
        <el-checkbox label="networkType">网络制式</el-checkbox>
        <el-checkbox label="productModelType">机型</el-checkbox>
        <el-checkbox label="createdName">创建人</el-checkbox>
        <el-checkbox label="urgentType">紧急程度</el-checkbox>
      </el-checkbox-group>
      <span slot="footer" class="dialog-footer">
        <el-button @click="ifshowshaixuan = false">仅此生效</el-button>
        <el-button type="primary" @click="savashaixuan()">保存设置</el-button>
      </span>
    </el-dialog>

    <el-dialog title="变更研发跟进人" :visible.sync="showChangeTechOperator">
      <el-select v-model="ChangeTechForm.personLiableName" placeholder="请选择">
        <el-option
          v-for="item in personlist"
          :key="item.userId"
          :label="item.name"
          :value="item.name"
        >
        </el-option>
      </el-select>

      <div slot="footer" class="dialog-footer">
        <el-button @click="showChangeTechOperator = false">取 消</el-button>
        <el-button type="primary" @click="RunChangeOperator">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import {
  softwareformlist,
  getOnesoftware,
  softwaresolve,
  transformUrl,
  softFinish,
  getOperatorListUrl,
  transformPersonLiableUrl,
  DevelopEditUrl,
} from "@/api/software";
import { gettransformdeptlisturl } from "@/api/jurisdiction";
import { translateDataToTree } from "@/utils/setMethods";
import {
  productModelTypeOptions,
  areaoptions,
  problemtypeaoptions,
  prodectoptions,
  netoptions,
  pingtaioptions,
  difoptions,
  attributeoptions,
} from "@/options/index";
import { suanfa } from "@/utils/index";

export default {
  name: "ArticleIndex",
  components: {},
  props: {},
  data() {
    return {
      innerVisible: false,
      esservedatiledata: {},
      ifshowshaixuan: false,
      GLYFcheckList: [
        "station",
        "personLiable",
        "process",
        "description",
        "remark",
        "area",
        "faeOperatorName",
        "customerName",
        "deptName",
        "salesman",
        "type",
        "productType",
        "networkType",
        "productModelType",
        "createdName",
        "urgentType",
      ],
      productModelType: productModelTypeOptions[0].name,
      productModelTypeOptions,
      areaoptions,
      problemtypeaoptions,
      prodectoptions,
      netoptions,
      pingtaioptions,
      difoptions,
      attributeoptions,
      tableHeight: window.innerHeight - 320,
      ifgaoji: false,
      imageList: [],
      fileList: [],
      solutionlinedata: [],
      remakelinedate: [],
      remarklist: [],
      solveremark: "",
      showkf: false,
      editform: {
        attribute: null,
        developStuckPoint: "",
        solution: "",
        tapdLink: "",
        workOrderId: null,
      },
      changepsdform: {
        oldPassword: "",
        newPassword: "",
      },
      options: [
        {
          value: null,
          label: "不限",
        },
        {
          value: "0",
          label: "已创建",
        },
        {
          value: "1",
          label: "已完成",
        },
        {
          value: "0,2,3,5",
          label: "未完成",
        },
      ],

      netoptions: [
        {
          value: "2G",
          label: "2G",
        },
        {
          value: "3G",
          label: "3G",
        },
        {
          value: "4G",
          label: "4G",
        },
      ],

      showchangepsw: false,
      complaterform: { workOrderId: null },
      delectform: { workOrderId: null },

      textarea: "",
      ComplectVisible: false,
      AddVisible: false,
      planVisable: false,
      deletedvis: false,
      multfilehead: {},
      multfiledata: {},

      dataildata: {},

      value: "",
      articles: [], // 文章数据列表
      urgentStatus: [
        { status: 0, text: "低", type: "info" }, // 1
        { status: 1, text: "中", type: "primary" }, // 1
        { status: 2, text: "高", type: "warning" }, // 1
        { status: 3, text: "极高", type: "danger" }, // 1`
      ],
      urgentStatustext: [
        "低", // 1
        "中", // 1
        "高", // 1
        "极高", // 1
      ],

      processStatus: [
        { status: 0, text: "未完成", type: "danger" }, // 1
        { status: 1, text: "已完成", type: "primary" }, // 1
      ],
      progressStatus: [
        { text: "个人", type: "warning" }, // 0
        { text: "客服", type: "success" }, // 1
        { text: "FAE", type: "primary" }, // 2
        { text: "研发", type: "danger" }, // 3
        { text: "小程序", type: "success" }, // 3
        { text: "电商", type: "success" }, // 3
      ],

      totalCount: 0, // 总数据条数
      status: null, // 查询文章的状态，不传就是全部
      channels: [], // 文章频道列表
      channelId: null, // 查询文章的频道
      rangeDate: null, // 筛选的范围日期
      loading: true, // 表单数据加载中 loading

      form: {
        workOrderNo: null,
        deptId: 0,
        ignoreUserFlag: false,
        creatTimeBefore: null,
        createTimeAfter: null,
        sourceType: null,
        ownerType: 3,
        size: 20, // 每页大小
        page: 1, // 当前页码
      },
      solvform: {
        complatetype: "",
        remark: "",
      },
      changeform: {
        urgentType: "",
        workOrderId: "",
        area: "",
        customerName: "",
        personLiable: "",
        description: "",
        keyword: "",
        networkType: "",
        platform: "",
        productModelType: "",
        productType: "",
        salesman: "",
        type: "",
      },
      changewindowsshow: false,
      kfform: {
        workOrderId: null,
      },
      kfdata: {},
      translateTO: 0,
      showtranslate: false,
      translateremark: "",
      treedata: [],
      deptId: "",
      urgentTypeoption: [
        {
          value: "0",
          label: "低",
        },
        {
          value: "1",
          label: "中",
        },
        {
          value: "2",
          label: "高",
        },
        {
          value: "3",
          label: "极高",
        },
      ],

      FAEdatile: {},
      kfdatile: {},
      yanfadatile: {},
      detail_kefu: false,
      dialogImageUrl: "",
      dialogVisible: false,
      FAEpreviewList: [],
      YFpreviewList: [],
      KFpreviewList: [],
      ESpreviewList: [],
      ChangeTechForm: {
        personLiableName: null,
        personLiableId: null,
      },
      showChangeTechOperator: false,
      personlist: [],
      steptype: 0,
    };
  },
  computed: {
    uploadHeaders() {
      const usertoken = JSON.parse(window.localStorage.getItem("usertoken"));
      return { Authorization: usertoken };
    },
  },
  watch: {
    "form.productModelType"(_new) {
      /**
       * 选择“机型”自动填写“产品类型”、“网络机制”
       * “其他机型”则手动选择
       */
      const { productType, networkType } =
        this.productModelTypeOptions.find(({ name }) => name === _new) ?? {};

      this.form.productType = productType;
      this.form.networkType = networkType;

      if (_new == "自定义") {
        this.ifshoutian = true;
      } else {
        this.ifshoutian = false;
      }
    },
  },
  created() {
    this.getOperatorList();
    this.form.workOrderNo = this.$route.query.orderNo;
    window.addEventListener("resize", this.getTableHeight);
  },
  destroyed() {
    window.removeEventListener("resize", this.getTableHeight);
  },
  mounted() {
    this.form.sourceType = null;
    this.getdeptlist();
    this.loadArticles(1);
    let token = JSON.parse(window.localStorage.getItem("user"));
    this.multfilehead = { Authorization: token };

    let checkList = JSON.parse(window.localStorage.getItem("GLYFcheckList"));
    if (checkList) {
      this.GLYFcheckList = checkList;
    }
  },
  methods: {
    showinnerVisible() {
      this.innerVisible = true;
      this.editform = {
        attribute: this.yanfadatile.attribute,
        developStuckPoint: this.yanfadatile.developStuckPoint,
        solution: this.yanfadatile.solution,
        tapdLink: this.yanfadatile.tapdLink,
        workOrderId: this.yanfadatile.workOrderId,
      };
      console.log(this.editform);
    },
    async EditSubmit() {
      try {
        const res = await DevelopEditUrl(this.editform);
        if (res.data.code === 200) {
          this.$message({
            message: "编辑成功",
            type: "success",
          });
        } else {
          this.$message({
            message: res.data.msg,
            type: "error",
          });
        }
      } catch (e) {
        this.$message({
          message: e,
          type: "error",
        });
      } finally {
        this.innerVisible = false;
        this.planVisable = false;
        this.loadArticles;
      }
    },
    async RunChangeOperator() {
      try {
        const res = await transformPersonLiableUrl(this.ChangeTechForm);
        if (res.data.code === 200) {
          this.$message({
            message: "更改研发人员成功",
            type: "success",
          });
          this.loadArticles();
        } else {
          this.$message({
            message: res.data.msg,
            type: "error",
          });
        }
      } catch (e) {
        this.$message({
          message: e,
          type: "error",
        });
      } finally {
        this.showChangeTechOperator = false;
      }
    },
    async getOperatorList() {
      try {
        const res = await getOperatorListUrl();
        if (res.data.code === 200) {
          this.personlist = res.data.data;
        } else {
          this.$message({
            message: res.data.msg,
            type: "error",
          });
        }
      } catch (e) {
        this.$message({
          message: e,
          type: "error",
        });
      }
    },
    ChangeTech(row, column, event) {
      this.ChangeTechForm.workOrderId = row;
      this.showChangeTechOperator = true;
    },
    savashaixuan() {
      window.localStorage.setItem(
        "GLYFcheckList",
        JSON.stringify(this.GLYFcheckList)
      );
      this.ifshowshaixuan = false;
    },
    showshaixuan() {
      this.ifshowshaixuan = true;
    },
    getTableHeight() {
      this.tableHeight = window.innerHeight - 320;
    },
    cleardata() {
      this.form = {
        deptId: 0,
        ignoreUserFlag: false,
        creatTimeBefore: null,
        createTimeAfter: null,
        sourceType: null,
        ownerType: 3,
        size: 20, // 每页大小
        page: 1, // 当前页码
      };
      this.loadArticles();
    },
    async finish(row, column, event) {
      try {
        const res = await softFinish({ workOrderId: row.workOrderId });
        if (res.data.code === 200) {
          this.$message({
            message: "此工单已完成",
            type: "success",
          });
          this.loadArticles(1);
          this.planVisable = false;
        } else {
          this.$message({
            message: res.data.msg,
            type: "error",
          });
        }
      } catch (e) {
        this.$message({
          message: e,
          type: "error",
        });
      } finally {
      }
    },

    handleSizeChange(val) {
      this.form.size = val;
      this.loadArticles();
    },
    handleUploadSuccess(response, file) {
      if (response.code === 200) {
        const { url, path } = response.data;
        this.fileList = [...this.fileList, { ...file, url, path }];
      } else {
        this.fileList = [...this.fileList];
        this.$message({
          message: response.data.msg,
          type: "error",
        });
      }
    },
    handleRemove(file, fileList) {
      this.fileList = fileList;
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    handleImageRemove(file, imageList) {
      this.imageList = imageList;
    },
    /* 图片增删改查 */
    handleImgUploadSuccess(response, image) {
      if (response.code === 200) {
        const { url, path } = response.data;
        this.imageList = [...this.imageList, { ...image, url, path }];
      } else {
        this.imageList = [...this.imageList];
        this.$message({
          message: response.data.msg,
          type: "error",
        });
      }
    },

    async getdeptlist() {
      try {
        const res = await gettransformdeptlisturl();
        if (res.data.code === 200) {
          this.treedata = translateDataToTree(res.data.data);
        } else {
          this.$message({
            message: res.data.msg,
            type: "error",
          });
        }
      } catch (e) {
        this.$message({
          message: e,
          type: "error",
        });
      }
    },
    showtranslatewindows(row, column, event) {
      this.translateTO = 0;
      this.showtranslate = true;
      this.translateId = row.workOrderId;
    },
    handleSelectDept(val) {
      if (val) {
        this.deptId = val[val.length - 1];
      }
    },
    tableRowClassName({ row, rowIndex }) {
      if (row.process == 1) {
        return "complate-row";
      } else if (row.notifiedFlag == true) {
        return "warning-row";
      }
      return "white-row";
    },

    async solveOrder() {
      try {
        const res = await softwaresolve({
          workOrderId: this.dataildata.workOrderId,
          mode: this.dataildata.mode,
          remark: this.dataildata.solveremark,
          attachment: (this.fileList || []).map(({ path }) => path).join(","),
          image: (this.imageList || []).map(({ path }) => path).join(","),
        });
        if (res.data.code === 200) {
          this.$message({
            message: "成功",
            type: "success",
          });
        } else {
          this.$message({
            message: res.data.msg,
            type: "error",
          });
        }
      } catch (e) {
        this.$message({
          message: e,
          type: "error",
        });
      } finally {
        this.imageList = [];
        this.fileList = [];
        this.dataildata.mode = null;
        this.dataildata.solveremark = null;
        this.planVisable = false;
        this.loadArticles;
      }
    },
    async translate() {
      if (this.translateremark == null || this.translateremark == "") {
        this.$message({
          message: "请输入退回原因",
          type: "success",
        });
      } else {
        try {
          const res = await transformUrl({
            workOrderId: this.translateId,
            sendBackReason: this.translateremark,
          });
          if (res.data.code === 200) {
            this.$message({
              message: "退回成功",
              type: "success",
            });
            this.loadArticles(1);
            this.planVisable = false;
          } else {
            this.$message({
              message: res.data.msg,
              type: "error",
            });
          }
        } catch (e) {
          this.$message({
            message: e,
            type: "error",
          });
        } finally {
          this.showtranslate = false;
          this.translateremark = "";
        }
      }
    },

    async addplan(row, column, event) {
      this.solutionlinedata = [];
      this.remakelinedate = [];

      this.planVisable = true;
      this.dataildata.workOrderId = row.workOrderId;
      // 初始化详情数据
      this.kfdatile = {};
      this.yanfadatile = {};
      this.FAEdatile = {};
      this.esservedatiledata = {};
      // this.solvform.personLiable = row.personLiable;
      try {
        const res = await getOnesoftware({ workOrderId: row.workOrderId });
        if (res.data.code === 200) {
          if (res.data.data.ecServeClientWorkOrderVo) {
            this.esservedatiledata = {
              ...res.data.data.ecServeClientWorkOrderVo,
              attachment: (
                res.data.data.ecServeClientWorkOrderVo.fileInfos || []
              ).map(({ path, url }) => ({
                path,
                url,
              })),
              image: (
                res.data.data.ecServeClientWorkOrderVo.imageInfos || []
              ).map(({ path, url }) => ({
                path,
                url,
              })),
            };

            if (this.esservedatiledata.type == 0) {
              this.esservedatiledata.type = "Unable to activate";
            } else if (this.esservedatiledata.type == 1) {
              this.esservedatiledata.type = "Offline";
            } else if (this.esservedatiledata.type == 2) {
              this.esservedatiledata.type = "APP";
            } else if (this.esservedatiledata.type == 3) {
              this.esservedatiledata.type = "Locate";
            } else if (this.esservedatiledata.type == 4) {
              this.esservedatiledata.type = "SIM card";
            } else if (this.esservedatiledata.type == 5) {
              this.esservedatiledata.type = "Package";
            } else if (this.esservedatiledata.type == 6) {
              this.esservedatiledata.type = "other";
            }

            if (this.esservedatiledata.usageScenario == 0) {
              this.esservedatiledata.usageScenario = "Vehicle";
            } else if (this.esservedatiledata.usageScenario == 1) {
              this.esservedatiledata.usageScenario = "Human";
            } else if (this.esservedatiledata.usageScenario == 2) {
              this.esservedatiledata.usageScenario = "Asset";
            } else if (this.esservedatiledata.usageScenario == 3) {
              this.esservedatiledata.usageScenario = "Other";
            }

            this.ESpreviewList = (
              res.data.data.ecServeClientWorkOrderVo.imageInfos || []
            ).map(({ url }) => url);
          }

          if (res.data.data.customerWorkOrderVo) {
            this.kfdatile = {
              ...res.data.data.customerWorkOrderVo,
              // attachment: (res.data.data.customerWorkOrderVo.fileInfos || []).map(
              //   ({ path, url }) => ({
              //     path,
              //     url,
              //   })
              // ),
              image: (res.data.data.customerWorkOrderVo.fileInfos || []).map(
                ({ path, url }) => ({
                  path,
                  url,
                })
              ),
            };
            this.KFpreviewList = (
              res.data.data.customerWorkOrderVo.fileInfos || []
            ).map(({ url }) => url);
          }

          if (res.data.data.developmentWorkOrderVo) {
            this.yanfadatile = {
              ...res.data.data.developmentWorkOrderVo,
              // attachment: (res.data.data.fileInfos || []).map(
              //   ({ path, url }) => ({
              //     path,
              //     url,
              //   })
              // ),
              // image: (res.data.data.imageInfos || []).map(({ path, url }) => ({
              //   path,
              //   url,
              // })),
            };
          }

          this.FAEdatile = {
            ...res.data.data.technicalSupportWorkOrderVo,
            attachment: (
              res.data.data.technicalSupportWorkOrderVo.fileInfos || []
            ).map(({ path, url }) => ({
              path,
              url,
            })),
            image: (
              res.data.data.technicalSupportWorkOrderVo.imageInfos || []
            ).map(({ path, url }) => ({
              path,
              url,
            })),
          };

          if (res.data.data.technicalSupportWorkOrderVo.process == 0) {
            this.steptype = 1;
          } else if (res.data.data.technicalSupportWorkOrderVo.process == 3) {
            this.steptype = 3;
          } else if (res.data.data.technicalSupportWorkOrderVo.process == 5) {
            this.steptype = 5;
          } else if (res.data.data.technicalSupportWorkOrderVo.process == 1) {
            this.steptype = 7;
          } else {
            this.steptype = 5;
          }
          // 预览图片列表
          this.YFpreviewList = (
            res.data.data.developmentWorkOrderVo?.imageInfos || []
          ).map(({ url }) => url);
          this.FAEpreviewList = (
            res.data.data.technicalSupportWorkOrderVo?.imageInfos || []
          ).map(({ url }) => url);
          if (this.FAEdatile.solution) {
            this.solutionlinedata = suanfa(this.FAEdatile.solution, "#solve#");
          }

          if (this.yanfadatile.remark) {
            this.remakelinedate = suanfa(this.yanfadatile.remark, "#reamrk#");
          }
        } else {
        }
      } catch (e) {
      } finally {
      }
    },

    async loadArticles(page = 1) {
      // 展示加载中 loading
      this.loading = true;
      if (this.ifshoutian == true && this.shoutianjixing != null) {
        this.form.productModelType = this.shoutianjixing;
      }
      try {
        const res = await softwareformlist(this.form);
        if (res.data.code === 200) {
          this.articles = res.data.data.record;

          for (let i = 0; i < this.articles.length; i++) {
            this.articles[i].remark = this.huanhang(this.articles[i].remark);
          }

          this.totalCount = res.data.data.total;
        } else {
          this.$message({
            message: res.data.msg,
            type: "error",
          });
        }
      } catch (e) {
        this.$message({
          message: e,
          type: "error",
        });
      } finally {
        this.loading = false;
      }

      // });
    },
    onCurrentChange(page) {
      this.loadArticles(page);
    },
    huanhang(cnmb) {
      if (cnmb) {
        return cnmb.replace(/#reamrk#/g, "\n\r").replace(/#time#/g, "--");
      } else {
        return "   ";
      }
    },
  },
};
</script>

<style scoped lang="less">
.header {
  float: right;
}
.filter-card {
  margin-bottom: 5px;
}

.list-table {
  margin-bottom: 10px;
}

.article-cover {
  width: 60px;
  background-size: cover;
}
.input {
  width: 130px;
  margin-right: 10px;
}
.flexparents {
  margin-bottom: 10px;
}
.dialogfooter {
  display: flex;
  justify-content: space-between;
}
.zdhh {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.rightgap {
  margin-right: 20px;
}
.el-select {
  width: 150px;
}
.leftgap {
  margin-left: 20px;
}
.rightgap {
  margin-right: 20px;
}
.kesuitem {
  margin: 9px;
}
.chongqi {
  background-color: green;
}
.yanfatable {
  .title {
    font-weight: bolder;
    color: black;
    font-size: 16px;
  }
  .table {
    border-style: solid;
    border-width: 1px;
    border-color: #bcbeb7;
  }
}

.destitle {
  padding-left: 18px;
  margin-top: 12px;
  margin-bottom: 12px;
  font-weight: bold;
  font-size: 18px;
  color: rgb(0, 0, 0);
}

/deep/.el-descriptions-item__label {
  font-weight: bold !important;
  color: rgb(0, 0, 0);
}

.desbasebody {
  padding: 15px;
  border: 1px solid #0a0a0a34;
  border-radius: 25px;
  background-color: #ecf4fd;
  /deep/.el-descriptions__table {
    background-color: #ecf4fd;
  }
}
.desbody {
  padding: 15px;
  border: 1px solid #0a0a0a34;
  border-radius: 25px;
}

/deep/.el-table .warning-row {
  background: oldlace;
  font-weight: bold;
  font-size: 12px;
}
/deep/.el-table .complate-row {
  background: #ffffff;
}
/deep/.el-table .white-row {
  background: #ffffff;
  font-weight: bold;
  font-size: 12px;
}
</style>
